<template>
  <div class="home-container">
    <div class="box main-box no-border-print">
      <div>
        <div class="tabs is-boxed is-small has-text-weight-bold">
          <ul>
            <li v-bind:class="{'is-active' : currentTab === 1}">
              <a @click="changeTab(1)">
                <span>HTML</span>
              </a>
            </li>
            <li v-bind:class="{'is-active' : currentTab === 2}" v-if="showCss">
              <a @click="changeTab(2)">
                <span>CSS</span>
              </a>
            </li>
            <li v-bind:class="{'is-active' : currentTab === 3}" v-if="showJs">
              <a @click="changeTab(3)">
                <span>JavaScript</span>
              </a>
            </li>
            <li v-bind:class="{'is-active' : currentTab === 4}" v-if="showJsLib">
              <a @click="changeTab(4)">
                <span>JS Libraries</span>
              </a>
            </li>
          </ul>
        </div>
        <div id="tab-content">
          <div v-show="currentTab===1">
            <div>
              <span class="keyword"> &nbsp;&nbsp; &lt;body&gt; </span>
            </div>
            <div>
              <div id="html-body-code" class="code-editor no-border-print html-body-editor"></div>
            </div>
            <div>
              <span class="keyword"> &nbsp;&nbsp; &lt;body&gt; </span>
            </div>
          </div>
          <div v-show="currentTab===2" v-if="showCss">
            <div class="css-inp">
              <div>
                <span class="keyword">&lt;style type="text/css"&gt; </span>
              </div>
              <div>
                <div id="css-code" class="code-editor css-editor no-border-print"></div>
              </div>
              <div class="keyword">&lt;/style&gt;</div>
            </div>
          </div>
          <div v-show="currentTab===3" v-if="showJs">
            <div class="js-inp">
              <div>
                <span class="keyword">&lt;script type="text/javascript"&gt; </span>
              </div>
              <div>
                <div id="js-code" class="code-editor js-editor no-border-print"></div>
              </div>
              <div class="keyword">&lt;/script&gt;</div>
            </div>
          </div>
          <div v-show="currentTab===4">
            <div class="jslib-inp">
              <div>
                <div id="html-head-code" class="js-editor no-border-print "></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="execute-section">
        <button class="button execute-button is-info has-text-white has-text-weight-bold" @click="ideExecute">
          <span v-show="!isCodeExecuting"><font-awesome-icon icon="play"/>&nbsp;&nbsp;&nbsp;Execute</span>
          <span v-if="isCodeExecuting">
            <font-awesome-icon icon="spinner" class="fa-spin"/>&nbsp;&nbsp;&nbsp;Executing...</span>
        </button>
      </div>
      <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger"
                     v-show="doodleForm.errorMessage"/>
      <div v-show="showOutput">
        <div class="has-text-weight-semibold ide-title">Output</div>
        <div class="output">
          <iframe name="output" frameborder="0" sandbox="allow-forms allow-popups allow-scripts allow-same-origin allow-modals"></iframe>
        </div>
      </div>
    </div>
    <div id="download-div" style="display:none">
      <form name="html-download-form" method="post" action="/api/doodle/download/web.html">
        <textarea name="code"></textarea>
      </form>
    </div>
  </div>
</template>

<script>
import aceMixin from './../assets/javascript/html-ide-mixin'
import DoodleMessage from '../components/Utils/DoodleMessage'
import formMixin from './../assets/javascript/form-mixin'
import loadScriptInBody from '../assets/javascript/loadScriptInBody'
import { ACE_UTIL } from '@/assets/javascript/ace-util'
import { IDE_CONST } from '../assets/javascript/constants'

export default {
  name: 'htmlIde',
  components: { DoodleMessage },
  mixins: [aceMixin, formMixin],
  data: function () {
    return {
      currentTab: 1,
      showOutput: false,
      showCss: true,
      showJs: true,
      showJsLib: true
    }
  },
  mounted () {
    loadScriptInBody.loadScriptInBody('/assets/jdoodle-pym.min.js')

    this.ideMeta = this.$route.meta
    ACE_UTIL.loadACELibraries()
    this.$_.delay(this.initEditors, 500, 1)

    let postAceAction = (count) => {
      if (window.ace) {
        this.pymChild = new window.pym.Child()
        this.pymChild.onMessage('setScript', (data) => {
          data = JSON.parse(data)
          if (data.id === this.$route.query.id) {
            this.initScripts(data)
          }
        })

        this.pymChild.sendMessage('getScript', JSON.stringify({ id: this.$route.query.id }))
      } else if (count < 20) {
        window._.delay(postAceAction, 600, count + 1)
      }
    }

    let postAction = (count) => {
      if (window.pym) {
        window.pym.Child({ polling: 500 })
        window._.delay(postAceAction, 100, 1)
      } else if (count < 20) {
        window._.delay(postAction, 600, count + 1)
      }
    }

    window._.delay(postAction, 600, 1)
  },
  methods: {
    changeTab (tab) {
      this.currentTab = tab
    },
    initScripts (data) {
      if (data.hideCss === 'true') {
        this.showCss = false
      }

      if (data.hideJs === 'true') {
        this.showJs = false
      }

      if (data.hideJsLib === 'true') {
        this.showJsLib = false
      }

      window.ace.edit(IDE_CONST.HTML_BODY_EDITOR_NAME).getSession().setValue(data.htmlScript)
      window.ace.edit(IDE_CONST.CSS_CODE_EDITOR_NAME).getSession().setValue(data.cssScript)
      window.ace.edit(IDE_CONST.JS_CODE_EDITOR_NAME).getSession().setValue(data.jsScript)
      window.ace.edit(IDE_CONST.HTML_HEAD_EDITOR_NAME).getSession().setValue(data.jsLibScript)
    },
    ideExecute () {
      this.execute()
      this.showOutput = true
    },
    postExecuteSuccessHandling () {

    }
  }
}
</script>

<style scoped lang="scss">
@import "../../node_modules/bulma-switch/dist/css/bulma-switch.min.css";
@import "./../assets/style/htmlide";

.main-box {
  padding: 0.5em 1.2em;
  background: #F2F2F2;
}

.home-container {
  margin-top: 0.1em;
}

.column {
  padding-top: 0.10em;
  padding-left: 0.50em;;
}

.execute-section {
  text-align: right;
  margin-right: 3%;
  position: relative;
}

.jslib-inp {
  margin-bottom: 10px;
}

</style>
