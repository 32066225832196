import { IDE_CONST } from './constants'
import { ACE_UTIL } from './ace-util'

export default {
  data: function () {
    return {
      isEmbed: false,
      editorHtmlDoctype: null,
      fileEditor: null,
      editorHtmlHead: null,
      editorHtmlBody: null,
      editorJs: null,
      editorCss: null,
      editors: [],
      isCodeExecuting: false,
      storage: null
    }
  },
  methods: {
    initEditors (count) {
      if (count > 10) {
        return
      }

      if (!window.ace) {
        this.$_.delay(this.initEditors, 500, count + 1)
        return
      }

      this.editorHtmlBody = ACE_UTIL.initEditor(IDE_CONST.HTML_BODY_EDITOR_NAME, 'html')
      this.editorHtmlBody.getSession().on('change', () => {
        ACE_UTIL.heightChangeFunction(IDE_CONST.HTML_BODY_EDITOR_NAME)
      })
      ACE_UTIL.heightChangeFunction(IDE_CONST.HTML_BODY_EDITOR_NAME, this.editorHtmlBody)
      this.editorHtmlBody.$highlightActiveLine = false

      this.editorHtmlHead = ACE_UTIL.initEditor(IDE_CONST.HTML_HEAD_EDITOR_NAME, 'html')
      this.editorHtmlHead.getSession().on('change', () => { ACE_UTIL.heightChangeFunction(IDE_CONST.HTML_HEAD_EDITOR_NAME) })
      ACE_UTIL.heightChangeFunction(IDE_CONST.HTML_HEAD_EDITOR_NAME, this.editorHtmlHead)
      this.editorHtmlHead.$highlightActiveLine = false

      this.editorJs = ACE_UTIL.initEditor(IDE_CONST.JS_CODE_EDITOR_NAME, 'javascript')
      this.editorJs.getSession().on('change', () => {
        ACE_UTIL.heightChangeFunction(IDE_CONST.JS_CODE_EDITOR_NAME)
      })
      ACE_UTIL.heightChangeFunction(IDE_CONST.JS_CODE_EDITOR_NAME, this.editorJs)
      this.editorJs.$highlightActiveLine = false

      this.editorCss = ACE_UTIL.initEditor(IDE_CONST.CSS_CODE_EDITOR_NAME, 'css')
      this.editorCss.getSession().on('change', () => {
        ACE_UTIL.heightChangeFunction(IDE_CONST.CSS_CODE_EDITOR_NAME)
      })
      ACE_UTIL.heightChangeFunction(IDE_CONST.CSS_CODE_EDITOR_NAME, this.editorCss)
      this.editorCss.$highlightActiveLine = false
    },
    execute () {
      let jsLibCode = (this.showJsLib === true) ? window.ace.edit(IDE_CONST.HTML_HEAD_EDITOR_NAME).getSession().getValue() : ''
      let cssCode = (this.showCss === true) ? window.ace.edit(IDE_CONST.CSS_CODE_EDITOR_NAME).getSession().getValue() : ''
      let jsCode = (this.showJs === true) ? window.ace.edit(IDE_CONST.JS_CODE_EDITOR_NAME).getSession().getValue() : ''
      let result =
        '<!DOCTYPE html> <html> <head>' +
        '<meta http-equiv="content-type" content="text/html; charset=UTF-8">' +
        '<meta name="robots" content="noindex, nofollow">' +
        '<meta name="googlebot" content="noindex, nofollow">' +
        '<style type="text/css">' + cssCode + '</style>' +
        '</head><body>' + window.ace.edit(IDE_CONST.HTML_BODY_EDITOR_NAME).getSession().getValue() + '\n' +
        jsLibCode +
        '<script type="text/javascript" async="true">' +
        jsCode +
        '</script>' +
        '</body></html>'

      $('form[name=html-download-form] textarea[name=code]').val(result)
      $('form[name=html-download-form]').attr('target', 'output')
      $('form[name=html-download-form]').submit()
    },
    postExecuteSuccessHandling () {

    },
    reset () {
      if (window.ace) {
        window.ace.edit(IDE_CONST.HTML_BODY_EDITOR_NAME).getSession().setValue('')
        window.ace.edit(IDE_CONST.CSS_CODE_EDITOR_NAME).getSession().setValue('')
        window.ace.edit(IDE_CONST.JS_CODE_EDITOR_NAME).getSession().setValue('')
      }

      $('iframe[name=output]').attr('src', 'about:blank')
    }
  }
}
